export const formatUrl = (url: string) => (url.startsWith('http') ? url : `http://${url}`);

export const toUrlParams = (params: Record<string, string | string[] | number>): string => {
	const queryString = Object.keys(params)
		.map(key => {
			const value = params[key];
			if (value === undefined || value === null) {
				return '';
			} else if (Array.isArray(value)) {
				return value.map(v => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
			} else {
				return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
			}
		})
		.filter(Boolean)
		.join('&');
	return queryString;
};

export const parseUrlFromMask = (url: string, mask: string): { [key: string]: string } => {
	const urlParts = url.split('/');
	const maskParts = mask.split('/');
	const params: { [key: string]: string } = {};

	return maskParts.reduce((params, part, index) => {
		const match = part.match(/^\[(.+)\]$/);
		if (match) {
			const paramName = match[1];
			params[paramName] = urlParts[index] || '';
		}
		return params;
	}, {} as { [key: string]: string });

	return params;
};
