import { ListMonthlySoldPoliciesResponse } from '@agentero/grpc-clients/grpc/tier-fe';

export type PoliciesMonthly = {
	month: number;
	year: number;
	numberPoliciesSold: number;
};

export type PoliciesSummary = {
	totalPoliciesSold: number;
	avPoliciesSold: number;
	lastMonthPoliciesSold: number;
	lastMonthName: string;
	nextMonthName: string;
};

export const parsePoliciesMonthly = (
	response: ListMonthlySoldPoliciesResponse.AsObject
): PoliciesMonthly[] => {
	const policiesMonthly = response.monthlySoldPoliciesList.map(policy => ({
		month: policy.month,
		year: policy.year,
		numberPoliciesSold: policy.totalPolicies
	}));

	return policiesMonthly.sort((a, b) => b.year - a.year || b.month - a.month);
};

export const getMonthName: (month: number) => string = (month: number): string => {
	const date = new Date(1970, month, 1);
	return date.toLocaleString('default', { month: 'long' });
};

export const getTotalPoliciesSold = (policiesMonthly: PoliciesMonthly[]): number => {
	return policiesMonthly.length
		? policiesMonthly.reduce((acc, policyMonthly) => acc + policyMonthly.numberPoliciesSold, 0)
		: 0;
};

export const getPoliciesSummary = (policiesMonthly: PoliciesMonthly[]): PoliciesSummary => {
	const totalPoliciesSold = getTotalPoliciesSold(policiesMonthly);

	const lastMonth = policiesMonthly.length ? policiesMonthly[policiesMonthly.length - 1].month : 1;

	const nextMonthNumber = lastMonth + 1 > 11 ? 0 : lastMonth + 1;

	const lastMonthName = getMonthName(lastMonth);
	const nextMonthName = getMonthName(nextMonthNumber);

	return {
		totalPoliciesSold,
		avPoliciesSold: policiesMonthly.length ? totalPoliciesSold / policiesMonthly.length : 0,
		lastMonthPoliciesSold: policiesMonthly.length
			? policiesMonthly[policiesMonthly.length - 1].numberPoliciesSold
			: 0,
		lastMonthName,
		nextMonthName
	};
};
