'use client';

import * as PopoverRadix from '@radix-ui/react-popover';

import { StyledComponent, styled } from '@agentero/styles/jsx';

const ContentRoot = styled(PopoverRadix.Content, {
	base: {
		zIndex: 1100,
		rounded: 'md',
		border: 'base',
		bg: 'white',
		p: '24',
		outline: 'none',
		borderRadius: '8',
		boxShadow: '0px 1px 5px 0px #16181D0A,0px 0px 4px 0px #16181D24,0px 3px 4px 0px #16181D1F'
	}
});

const Content: StyledComponent<
	React.ForwardRefExoticComponent<
		PopoverRadix.PopoverContentProps & React.RefAttributes<HTMLDivElement>
	>
> = ({ children, sideOffset = 5, ...props }) => {
	return (
		<PopoverRadix.Portal>
			<ContentRoot sideOffset={sideOffset} {...props}>
				{children}
			</ContentRoot>
		</PopoverRadix.Portal>
	);
};

export const Popover = {
	...PopoverRadix,
	Content,
	Portal: undefined
};
