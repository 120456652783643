export const getUtcMilliseconds = (date: Date) =>
	Date.UTC(
		date.getUTCFullYear(),
		date.getUTCMonth(),
		date.getUTCDate(),
		date.getUTCHours(),
		date.getUTCMinutes(),
		date.getUTCSeconds()
	);

export const parseDateStringToUTC = (formDate: string) => {
	const date = formDate.split('/');
	const year = Number(date[2]);
	const month = Number(date[0]) - 1;
	const day = Number(date[1]);
	return Date.UTC(year, month, day);
};

type ParseDateFromStringArgs = {
	date: string;
	language: string;
};

export const parseDateFromString = ({ date, language }: ParseDateFromStringArgs) =>
	parseDate({ date: new Date(date), language });

export interface IParseDate {
	date: Date;
	language: string;
	options?: Intl.DateTimeFormatOptions;
}

export const parseDate = ({ date, language }: IParseDate) => {
	const adjustedDate = getDateWithoutTimezoneOffset(date);

	return adjustedDate.toLocaleDateString(language, {
		month: '2-digit',
		year: 'numeric',
		day: '2-digit'
	});
};

export const parseUSDate = (date: Date) => {
	const adjustedDate = getDateWithoutTimezoneOffset(date);

	return adjustedDate.toLocaleDateString('en-US', {
		month: '2-digit',
		year: 'numeric',
		day: '2-digit'
	});
};

export const parseNoTZDate = (date: Date) =>
	date.toLocaleDateString('en-US', {
		month: '2-digit',
		year: 'numeric',
		day: '2-digit'
	});

export const parseDateTime = ({
	date,
	language,
	options = { timeStyle: 'medium' }
}: IParseDate & { options?: { timeStyle: string } }) => {
	return new Date(date).toLocaleTimeString(language, options);
};

const daySeconds = 60 * 60 * 24;

export const calculateDaysDiff = (from: Date, to: Date) => {
	const fromSeconds = getUtcMilliseconds(from) / 1000;
	const toSeconds = getUtcMilliseconds(to) / 1000;

	const diffTime = Math.abs(toSeconds - fromSeconds);
	return Math.floor(diffTime / daySeconds);
};

export const calculateMonthsDiff = (from: Date, to: Date) => {
	const monthsDiffFromYears = (to.getFullYear() - from.getFullYear()) * 12;

	const monthsDiff = to.getMonth() - from.getMonth();
	return Math.abs(monthsDiffFromYears + monthsDiff);
};

declare global {
	interface Date {
		sameDay(d: Date): boolean;
	}
}

Date.prototype.sameDay = function (d: Date) {
	return (
		this.getFullYear() === d.getFullYear() &&
		this.getDate() === d.getDate() &&
		this.getMonth() === d.getMonth()
	);
};

export const parseDateToRandom = ({ date, language }: IParseDate) => {
	const month = date.toLocaleDateString(language, { month: 'long' });
	const day = date.toLocaleDateString(language, { day: 'numeric' });
	const year = date.toLocaleDateString(language, { year: 'numeric' });

	let cardinalDay = 'th';
	const dayNumber = Number(day);

	if (dayNumber === 1) {
		cardinalDay = 'st';
	} else if (dayNumber === 2) {
		cardinalDay = 'nd';
	} else if (dayNumber === 3) {
		cardinalDay = 'rd';
	}

	return `${month} ${day}${cardinalDay}, ${year}`;
};

export const parseDateToMonthDayYear = ({ date, language, options }: IParseDate) =>
	new Intl.DateTimeFormat(language, {
		day: 'numeric',
		month: 'short',
		year: 'numeric',
		...options
	}).format(new Date(date));

export const isValidUSDate = (dateString: string) => {
	// First check for the pattern
	if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

	// Parse the date parts to integers
	const parts = dateString.split('/');
	const day = parseInt(parts[1], 10);
	const month = parseInt(parts[0], 10);
	const year = parseInt(parts[2], 10);

	// Check the ranges of month and year
	if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

	const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

	// Adjust for leap years
	if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29;

	// Check the range of the day
	return day > 0 && day <= monthLength[month - 1];
};

export const getDaysDiffBetweenDates = (dateInitial: Date, dateFinal: Date) =>
	(dateFinal.getTime() - dateInitial.getTime()) / (1000 * 3600 * 24);

export const parseDateToPeriod = ({ date, language }: IParseDate): string => {
	const adjustedDate = getDateWithoutTimezoneOffset(date);

	return new Intl.DateTimeFormat(language, {
		month: 'long',
		year: 'numeric'
	})
		.formatToParts(adjustedDate)
		.map(({ type, value }) => {
			return type === 'literal' ? ', ' : value;
		})
		.join('');
};

export const parseDateToMonth = ({ date, language }: IParseDate): string => {
	const adjustedDate = getDateWithoutTimezoneOffset(date);

	return adjustedDate.toLocaleString(language, { month: 'short' });
};

export const parseDateToLargeString = ({ date, language }: IParseDate): string => {
	const adjustedDate = getDateWithoutTimezoneOffset(date);

	return adjustedDate.toLocaleString(language, { month: 'long', day: 'numeric', year: 'numeric' });
};

export const getDateWithoutTimezoneOffset = (date: Date) => {
	const d = new Date(date);
	const timeDiff = d.getTimezoneOffset() * 60000;
	return new Date(d.valueOf() + timeDiff);
};

export const getAllYearsFrom = (startYear: number) => {
	const currentYear = new Date().getFullYear();

	return Array.from({ length: currentYear - startYear + 1 }, (_, index) => {
		return startYear + index;
	});
};

export const getAllMonths = ({
	language,
	options = { month: 'long' }
}: {
	language: string;
	options?: Intl.DateTimeFormatOptions;
}) =>
	Array.from({ length: 12 }, (_, index) => {
		return new Date(0, index, 1).toLocaleDateString(language, options);
	});

export const parseDateString = (value: string) => {
	const parsedDate =
		isValidUSDate(value) || !value
			? value
			: new Date(`${value.split('/')[2]}-${value.split('/')[0]}-${value.split('/')[1]}`);

	return parsedDate;
};

export const getValidMonths = (date: Date, locale: string) => {
	const currentDate = new Date();
	const months = Array.from({ length: 12 }).map((_, index) => {
		const value = new Date(date.getFullYear(), index + 1, 0);
		const label = value.toLocaleString(locale, {
			month: 'short',
			year: 'numeric'
		});
		const disabled =
			value.getFullYear() <= currentDate.getFullYear() && value.getMonth() < currentDate.getMonth();
		return { value, label, disabled };
	});

	return months;
};

export const getValidDateOrNull = (date: Date, recordDate = new Date(1900, 0, 1)) =>
	date > recordDate ? date : null;

export const getValidDateOrUndefined = (date: Date, recordDate = new Date(1900, 0, 1)) =>
	date > recordDate ? date : undefined;

export const getDateFromStringForm = (formDate: string) => {
	const date = formDate.split('/');
	return new Date(`${date[2]}-${date[0]}-${date[1]}`);
};

export const getYears = (startYear: number, endYear?: number) => {
	let years: number[] = [];
	const currentYear = endYear ?? new Date().getFullYear();
	while (startYear <= currentYear) {
		years = [...years, startYear++];
	}
	return years;
};

export const getAge = (dob: Date) => {
	const ageDifMs = Date.now() - dob.getTime();
	const ageDate = new Date(ageDifMs);
	return ageDate.getUTCFullYear() - 1970;
};

export const getDateFormatFromString = (seconds: number): string => {
	const milliseconds = seconds * 1000;

	const date = new Date(milliseconds);

	const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months start from 0 so 1 is added up
	const day = String(date.getUTCDate()).padStart(2, '0');
	const year = date.getUTCFullYear();

	return `${month}/${day}/${year}`;
};

export const isFullDate = (date: string) => {
	const dateParts = date.split('/');

	if (dateParts[0].length != 2 || isNaN(Number(dateParts[0]))) {
		return false;
	}

	if (dateParts[1].length != 2 || isNaN(Number(dateParts[1]))) {
		return false;
	}

	if (dateParts[2].length != 4 || isNaN(Number(dateParts[2]))) {
		return false;
	}

	return true;
};

export const getUtcDateFromStringDate = (stringDate: string) => {
	return new Date(`${stringDate}Z`);
};
